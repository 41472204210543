import React from "react"
import { graphql } from "gatsby"

import Banner from "../containers/Banner"
import BlogPostsList from "../containers/BlogPostsList"
import Breadcrumbs from "../containers/Breadcrumbs"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import NewEbook from "../containers/NewEbook"
import Newsletter from "../containers/Newsletter"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const BlogCategoryTemplate = ({ data, location }) => {
  const { edges } = data.allPrismicBlogPost

  const {
    title,
    image,
    subtitle,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = data.prismicBlog.data

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <Banner title={title.text} subtitle={subtitle} image={image.url} />
          <Newsletter pathname={location.pathname} />
          <Breadcrumbs page="Blog" authors />
          <NewEbook />
          <BlogPostsList content={edges} />
          <Consultation pathname={location.pathname} />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlogCategoryTemplate

export const blogCategoryQuery = graphql`
  query blogCategory($category: String!) {
    prismicBlog {
      data {
        seo_description {
          text
        }
        seo_short_description {
          text
        }
        seo_title {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        title {
          text
        }
        subtitle
        image {
          url
        }
      }
    }
    allPrismicBlogPost(
      filter: { data: { category: { eq: $category } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            category
            featured_article
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            author {
              slug
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    occupation {
                      text
                    }
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 60, quality: 72) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
